* {
  box-sizing: border-box;
}

*:focus {
  outline: 2px solid #00594f;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

address {
  font-style: normal;
}

ul {
  padding: 0;
  list-style: none;
}

button {
  cursor: pointer;
}
