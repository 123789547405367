@font-face {
    font-family: "Radnika";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-Regular.eot");
    src: url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-Regular.eot?#iefix") format("embedded-opentype"),
    url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-Regular.woff2") format("woff2"),
    url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-Regular.woff") format("woff");
}

@font-face {
    font-family: "Radnika";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-Italic.eot");
    src: url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-Italic.eot?#iefix") format("embedded-opentype"),
    url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-Italic.woff2") format("woff2"),
    url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-Italic.woff") format("woff");
}

@font-face {
    font-family: "Radnika";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-Black.eot");
    src: url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-Black.eot?#iefix") format("embedded-opentype"),
    url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-Black.woff2") format("woff2"),
    url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-Black.woff") format("woff");
}

@font-face {
    font-family: "Radnika";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-BlackItalic.eot");
    src: url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-BlackItalic.woff2") format("woff2"),
    url("https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com/common/fonts/Radnika-BlackItalic.woff") format("woff");
}


html,
body {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #fff9ea;
    color: #2b2525;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 100%;
}

.center-container-600 {
    margin: auto;
    max-width: 600px;
    text-align: center;
}

.product-image-container {
    img {
        width: 125px;
        height: 98px;
        object-fit: contain;
    }
}


.product-small-image-container {
    img {
        width: 75px;
        height: auto;
        object-fit: contain;
    }
}

.pointer {
    cursor: pointer;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mr-2 {
    margin-right: 10px;
}

.mr-3 {
    margin-right: 15px;
}

.mx-3 {
    margin-left: 15px;
    margin-right: 15px;
}

.mr-5 {
    margin-right: 30px;
}

.mt-5 {
    margin-top: 30px;
}

.no-border * {
    border: none !important;
}

/* ::selection {
  color: #fff;
  background: #e79042;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    outline: none;
    margin: 0;
}
